<template>
  <div style="width: 100%;">
    <div v-if="!mounted">Chargement ...</div>
    <div v-else class="mb-4" style="margin: 0 -6px 0 0;">
      <v-row class="d-flex mb-4">
        <h4 style="margin-left: 15px;">Organisation des groupes</h4>
      </v-row>
      <v-row class="mt-n8">
        <GroupeParametrage
          :context="{
            ID_Structure: currentStructureId,
            ID_Saison: selectedSeason.id,
          }"
          :headers="headers"
          :mounted="mounted"
        ></GroupeParametrage>
      </v-row>
    </div>
  </div>
</template>

<script>
import GroupeParametrage from "@/components/GestionDeLaStructure/Affiliations/Groupe_parametrage.vue";
import { mapGetters } from "vuex";

export default {
  components: { GroupeParametrage },
  data: () => ({
    selectedSeason: null,
    mounted: false,

    // headers: ["Section", "Nom du groupe", "Age Mini", "Age Maxi", "Actions "],
    headers: [
      {
        text: "Activité",
        value: "LIST_ActiviteType.NomActivite",
      },
      { text: "Nom du groupe", value: "NomGroupe" },
      // { text: "Jauge", value: "STR_GroupeSaisonCreneau[0].QuotasMax" },
      { text: "Jauge", value: "Jauge" },
      { text: "Age minimum", value: "AgeMinimum" },
      { text: "Age maximum", value: "AgeMaximum" },
      { text: "Action", value: "actions" },
    ],
  }),

  async created() {
    this.mounted = true;
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
    ...mapGetters("saisons", ["saisons"]),
  },
};
</script>
