<template>
  <div style="width: 100%; overflow: hidden;" v-if="mounted">
    <v-row class="px-4">
      <v-col class="text-left">
        <InputSelect
          v-model="saisonSelected"
          :items="saisons"
          item_text="name"
          item_value="value"
          rules="required"
          class="label__saison"
          @input="onChangeInput"
        />
      </v-col>
      <v-col class="text-right">
        <v-btn
          class="mx-2"
          fab
          small
          dark
          color="indigo"
          @click.prevent="openCreneauxModal"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loading"
      dense
      :headers="headers"
      :items="dataGroupeCreneaux"
      item-key="id"
      class="elevation-1 list__table"
    >
      <template v-slot:[`item.Jauge`]="{ item }">
        <span v-text="jauge(item)" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="green"
              outlined
              @click.prevent="editCreneaux(item)"
              key="edit"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifier le groupe</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="error"
              outlined
              @click.prevent="opendeleteModal(item)"
              key="delete"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Supprimer le groupe</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- modal to open configuration créneaux -->
    <LicenceDematerialise
      :modalIsOpen="creneauModalOpen"
      @onClose="creneauModalOpen = false"
      :context="context"
      :defaultCreneau="itemData"
      :isEdit="isEdit"
      :saisonGroupe="saisonSelected"
      @reloadGroupe="reloadData"
    />

    <!-- fin modal to open configuration créneaux -->
    <YesNo
      :showDialog="confirmDeleteModal"
      title="CONFIRMATION"
      text="Souhaitez-vous supprimer ce groupe ?"
      @answerYes="deleteCreneaux"
      @answerNo="confirmDeleteModal = false"
      @close="confirmDeleteModal = false"
    ></YesNo>
  </div>
  <!-- modal delete -->

  <div v-else>Chargement ...</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import RowClubParams from "@/components/GestionDeLaStructure/Affiliations/Club_paramétrage_row.vue";
import LicenceDematerialise from "@/components/utils/modals/Licence/LicenceDematerialise_Modal.vue";
import YesNo from "@/components/utils/modals/yes-no.vue";
import InputSelect from "@/components/utils/select.vue";

export default {
  components: { YesNo, LicenceDematerialise, InputSelect },

  data: () => ({
    mounted: false,
    loading: true,
    creneauModalOpen: false,
    isEdit: false,
    itemData: {},
    confirmDeleteModal: false,
    itemToDelete: {},
    itemToEdit: null,
    saisonSelected: null,
    dataGroupeCreneaux: [],
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    this.loading = false;
  },

  computed: {
    ...mapGetters("structure", [
      "getStructure",
      "currentStructureType",
      "hasCodeFederal",
      "getListCreneaux",
      "getGroupeCreneaux",
    ]),
    ...mapGetters("saisons", ["getSaison", "currentSaison"]),
    SaisonName() {
      return this.getSaison(this.context.ID_Saison).NomSaison;
    },

    saisons() {
      let saisons = [];
      for (let saison = this.currentSaison.id; saison >= 2020; saison--) {
        saisons = [
          ...saisons,
          { name: `Saison ${saison}-${saison + 1}`, value: saison + 1 },
        ];
      }
      return saisons;
    },
  },

  methods: {
    ...mapActions("structure", [
      "getGroupeCreneauFullData",
      "deleteGroupeCreneaux",
      "getGroupeCreneauBySaison",
    ]),
    jauge(item) {
      const licenceNonRefused = item.STR_GroupeSaisonCreneau[0]?.UTI_UtilisateursTemps.filter(
        (user) => user.Status !== 2
      );
      return `${licenceNonRefused.length}/${item.STR_GroupeSaisonCreneau[0]?.QuotasMax}`;
    },
    async onChangeInput() {
      await this.getGroupeCreneauBySaison({
        ID_Saison: this.saisonSelected,
        ID_Structure: this.context.ID_Structure,
      });
      const dataFilterBySaison = [
        ...this.getGroupeCreneaux.filter(
          (group) =>
            group.ID_Saison === this.saisonSelected && group.EST_Actif === true
        ),
      ];
      this.dataGroupeCreneaux = dataFilterBySaison;
    },
    async setData() {
      // get list creneauxd
      this.saisonSelected = this.context.ID_Saison;
      this.loading = true;

      await this.getGroupeCreneauFullData({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.context.ID_Saison,
      });
      this.loading = false;
      //

      const dataFilterBySaison = [
        ...this.getGroupeCreneaux.filter(
          (group) => group.ID_Saison === this.saisonSelected
        ),
      ];
      this.dataGroupeCreneaux = dataFilterBySaison;
    },

    async reloadData() {
      // await this.setData();
      await this.onChangeInput();
    },
    openCreneauxModal() {
      this.isEdit = false;
      this.itemData = {};
      this.creneauModalOpen = true;
    },
    editCreneaux(item) {
      this.isEdit = true;
      // editer la partie modal creneaux
      this.creneauModalOpen = true;
      this.itemData = { ...item }; //chargement des données pré-remplies
    },
    opendeleteModal(item) {
      this.confirmDeleteModal = true;
      // chargement données item to delete
      this.itemToDelete = { ...item };
    },

    async deleteCreneaux() {
      // request to delete creneaux correspondant à une structure ID_Structure (club)+ id creneau
      this.loading = true;
      try {
        await this.deleteGroupeCreneaux({
          ID_CreanauGroupe: this.itemToDelete.id,
          ID_Saison: this.itemToDelete.ID_Saison,
        });
        this.loading = false;
        this.confirmDeleteModal = false;
        this.reloadData();
      } catch (error) {
        console.log(error);
      }
    },
  },

  props: {
    //Context var like ID_Saison, ID_Structure
    context: {
      type: Object,
    },
    headers: {
      type: Array,
    },
  },
};
</script>
<style scoped>
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
</style>
