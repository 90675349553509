<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="close()"
    max-width="90vw"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column;">
        <span style="font-size: 1.2rem;">
          GROUPE:
          {{ defaultCreneau.NomGroupe ? defaultCreneau.NomGroupe : "" }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="px-5 mt-2">
          <ValidateForm
            @validated="submit"
            :loading="loading"
            :isNeeded="false"
            :showFieldObl="false"
            @onError="activeTab = 0"
          >
            <v-row>
              <v-col cols="12" sm="6" md="3" lg="3">
                <InputText
                  type="text"
                  rules="required"
                  label="Nom du groupe"
                  v-model="GroupeCreneau.NomGroupe"
                />

                <InputSelect
                  :items="sectionList"
                  label="Activité"
                  item_text="NomActivite"
                  item_value="id"
                  rules="required"
                  v-model="ID_Section"
                  key="SectionName"
                />

                <InputSelect
                  :items="modalityOrganizationList"
                  label="Modalité d'organisation"
                  item_text="text"
                  item_value="value"
                  rules="required"
                  v-model="GroupeCreneau.ModaliteOrganisation"
                />

                <InputSelect
                  v-if="GroupeCreneau.ModaliteOrganisation === 1"
                  :items="listPratiqueType"
                  label="Type de pratique"
                  item_text="NomTypePratique"
                  item_value="id"
                  rules="required"
                  v-model="GroupeCreneau.ID_PratiqueType"
                />

                <InputSelect
                  v-if="GroupeCreneau.ModaliteOrganisation === 1"
                  :items="listPratiqueNiveau"
                  label="Niveau"
                  item_text="NomNiveauPratique"
                  item_value="id"
                  rules="required"
                  v-model="GroupeCreneau.ID_PratiqueNiveau"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="4">
                <InputSelect
                  v-model="OptionsAdhesions"
                  :items="optionClub"
                  label="Option(s) d'adhésion"
                  item_text="NomEtPrix"
                  item_value="id"
                  key="tarifs"
                  multiple
                />
                <!-- :readonly="isAction"-->
                <InputText
                  type="number"
                  rules="required"
                  label="Age minimum"
                  v-model="GroupeCreneau.AgeMinimum"
                  key="ageMin"
                  class="minimun__age"
                  inputStyle="width:25%"
                >
                  <span v-if="error" class="form__error-message">
                    Age min supérieur age max
                  </span>
                </InputText>

                <InputText
                  type="number"
                  rules="required"
                  label="Age maximum"
                  v-model="GroupeCreneau.AgeMaximum"
                  key="ageMax"
                  inputStyle="width:25%"
                />
              </v-col>
              <v-col cols="12" sm="6" md="5" lg="5">
                <Textarea
                  rules="required"
                  label="Description"
                  style="height: 88px !important;"
                  placeholder="Informations et détails sur le groupe"
                  v-model="GroupeCreneau.Description"
                ></Textarea>

                <v-row class="d-flex align-content-center">
                  <v-col cols="3">
                    <label for="checkbox" style="font-size: 12.8px; margin: 0;">
                      Mode de paiement*</label
                    >
                  </v-col>
                  <v-col cols="9" class="d-flex">
                    <v-checkbox
                      style="width: 50%;"
                      v-model="GroupeCreneau.EST_PaiementCB"
                      label="CB"
                      class="selection_vente"
                      @change="GroupeCreneau.EST_PaiementAutre = false"
                    ></v-checkbox>
                    <v-checkbox
                      style="width: 50%;"
                      v-model="GroupeCreneau.EST_PaiementAutre"
                      label="Autres"
                      class="selection_vente"
                      @change="GroupeCreneau.EST_PaiementCB = false"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <v-tabs
              v-model="activeTab"
              class="tabs_c_list mt-4"
              background-color="#E0E0E0"
              grow
            >
              <v-tab> Paramétrages </v-tab>
              <v-tab> Pratiquants </v-tab>
              <v-tab> Créneaux </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row
                      class="d-flex align-items-center"
                      style="width: 100%; height: 60px;"
                    >
                      <v-col cols="2">
                        <label
                          for="checkbox"
                          style="font-size: 12.8px; margin: 0;"
                        >
                          Choisir le tarif club*</label
                        >
                      </v-col>
                      <v-col cols="10">
                        <InputSelect
                          v-model="ID_Tarif"
                          :items="tarifsClub"
                          rules="required"
                          item_text="NomEtPrix"
                          item_value="id"
                          key="tarifs"
                        />
                        <!-- :readonly="isAction"  -->
                      </v-col>
                    </v-row>
                    <v-row
                      class="d-flex align-items-center"
                      style="width: 100%; height: 60px;"
                    >
                      <v-col cols="2">
                        <label
                          for="checkbox"
                          style="font-size: 12.8px; margin: 0;"
                        >
                          Accès au formulaire d'inscription</label
                        >
                      </v-col>
                      <v-col cols="10" class="d-flex">
                        <v-checkbox
                          style="width: 15%;"
                          v-model="EST_VenteOuverteExterne"
                          label="Ext"
                          class="selection_vente"
                        >
                        </v-checkbox>
                        <!--:readonly="isAction"-->
                        <v-checkbox
                          style="width: 15%;"
                          v-model="EST_VenteOuverteClub"
                          label="Club"
                          class="selection_vente"
                        ></v-checkbox>
                        <!--:readonly="isAction"-->
                      </v-col>
                    </v-row>
                    <v-row
                      class="d-flex align-items-center"
                      style="width: 100%; height: 60px;"
                    >
                      <v-col cols="2">
                        <label
                          for="checkbox"
                          style="font-size: 12.8px; margin: 0;"
                        >
                          Nombre de pratiquant max*</label
                        >
                      </v-col>
                      <v-col cols="10">
                        <InputText
                          type="number"
                          placeholder="10"
                          v-model="GroupeSaison.QuotasMax"
                          inputStyle="width:8%"
                          rules="required"
                        />
                        <!--:readonly="isAction"-->
                      </v-col>
                    </v-row>
                    <v-row
                      class="d-flex align-items-center"
                      style="width: 100%; height: 60px;"
                    >
                      <v-col cols="2" sm="6" md="2" lg="2">
                        <label
                          for="checkbox"
                          style="font-size: 12.8px; margin: 0;"
                        >
                          Encadrant référent*</label
                        >
                      </v-col>
                      <v-col
                        cols="9"
                        sm="6"
                        md="4"
                        lg="3"
                        class="encadrant__choice"
                        style="padding-right: 0;"
                      >
                        <InputText
                          style="width: 240px;"
                          v-model="NomEncadrant"
                          rules="min:0|max:50"
                          disabled
                        ></InputText
                      ></v-col>

                      <v-col cols="1" sm="6" md="4" lg="7">
                        <v-btn
                          class="my-1"
                          fab
                          x-small
                          dark
                          color="cyan"
                          @click="openSelectUserModal"
                        >
                          <v-icon dark>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                          class="my-1 ml-2"
                          fab
                          x-small
                          dark
                          color="cyan"
                          @click.prevent="deleteEncadrant"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :loading="loading"
                    dense
                    sort-by-text
                    :headers="participantsHeaders"
                    :items="participants"
                    item-key="id"
                    class="elevation-1 list__table list__participant"
                  >
                    <template v-slot:[`item.LicenceNumero`]="{ item }">
                      <span
                        v-if="item.LicenceNumero != 'null'"
                        v-text="item.LicenceNumero"
                      />
                      <v-chip
                        class="ma-2"
                        color="black"
                        v-text="'N.C'"
                        text-color="white"
                      />
                    </template>
                    <template v-slot:[`item.Status`]="{ item }">
                      <div v-if="item.Status === 0" @click="test(item)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-1"
                              v-on="on"
                              v-bind="attrs"
                              fab
                              x-small
                              color="green"
                              outlined
                              @click.prevent="acceptParticipant(item)"
                              key="edit"
                            >
                              <v-icon>mdi-checkbox-marked-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Accepter la demande</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-1"
                              v-on="on"
                              v-bind="attrs"
                              fab
                              x-small
                              color="error"
                              outlined
                              @click.prevent="refuseParticipant(item)"
                              key="delete"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Refuser la demande</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-1"
                              v-on="on"
                              v-bind="attrs"
                              fab
                              x-small
                              color="error"
                              outlined
                              @click.prevent="deleteParticipant(item)"
                              key="delete"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Supprimer la demande</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <v-chip
                          @click="test(item)"
                          class="ma-2"
                          :color="
                            item.Status === 2
                              ? 'error'
                              : item.Status === 3
                              ? 'success'
                              : 'light-grey'
                          "
                          v-text="
                            item.Status === 2
                              ? 'Demande refusée'
                              : item.Status === 3
                              ? 'Demande validée'
                              : 'En attente de paiement'
                          "
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :loading="loading"
                    dense
                    :headers="headers"
                    :items="listCreneau"
                    item-key="id"
                    class="elevation-1 list__table creneau__list"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialog"
                          max-width="520px"
                          class="tabs__button"
                        >
                          <template v-slot:activator="{ attrs }">
                            <v-btn
                              class="mx-2"
                              fab
                              small
                              dark
                              color="indigo"
                              v-bind="attrs"
                              @click="openHoraireModal"
                            >
                              <!--:readonly="isAction"-->
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-toolbar color="blue-dark" dark>{{
                              editHoraire
                                ? "Modifier un créneau horaire"
                                : "Ajouter un créneau horaire"
                            }}</v-toolbar>
                            <v-card-text>
                              <ValidateForm
                                @validated="submit"
                                :loading="loading"
                              >
                                <v-container>
                                  <InputSelect
                                    v-model="editedCreneau.ID_NumeroJour"
                                    :items="allDays"
                                    rules="required"
                                    :item_text="allDays.text"
                                    :item_value="allDays.value"
                                    key="jours"
                                    label="Jour"
                                  />

                                  <v-row>
                                    <v-col cols="3" class="field_creneau"
                                      >Heure début *</v-col
                                    >
                                    <v-col>
                                      <v-menu
                                        ref="menu"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="
                                          editedCreneau.Z_HeureDebut
                                        "
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="editedCreneau.Z_HeureDebut"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                          v-if="menu1"
                                          format="24hr"
                                          v-model="editedCreneau.Z_HeureDebut"
                                          full-width
                                          @click:minute="
                                            $refs.menu.save(
                                              editedCreneau.Z_HeureDebut
                                            )
                                          "
                                        ></v-time-picker>
                                      </v-menu>
                                      <span
                                        v-if="errorInput"
                                        class="form__error-message"
                                      >
                                        Heure début supérieure heure fin</span
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="3" class="field_creneau"
                                      >Heure fin *</v-col
                                    >
                                    <v-col>
                                      <v-menu
                                        ref="menu2"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="
                                          editedCreneau.Z_HeureFin
                                        "
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="editedCreneau.Z_HeureFin"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-time-picker
                                          v-if="menu2"
                                          v-model="editedCreneau.Z_HeureFin"
                                          full-width
                                          format="24hr"
                                          @click:minute="
                                            $refs.menu2.save(
                                              editedCreneau.Z_HeureFin
                                            )
                                          "
                                        ></v-time-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>

                                  <InputSelect
                                    :items="adressesStructure"
                                    key="ID_AdressePratique"
                                    label="Lieu de pratique*"
                                    item_text="adresseVille"
                                    item_value="id"
                                    v-model="editedCreneau.ID_AdressePratique"
                                  />

                                  <Textarea
                                    label="Description"
                                    v-model="editedCreneau.Description"
                                  ></Textarea>
                                </v-container>
                              </ValidateForm>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red"
                                text
                                class="__full mt-0 mr-3 text-uppercase text-white"
                                @click="dialog = false"
                              >
                                Annuler
                              </v-btn>
                              <v-btn
                                color="blue-dark text-white font-weight-bold"
                                rounded
                                small
                                class="__full __icon-left"
                                :disabled="isDisabled"
                                @click.prevent="save"
                              >
                                {{ editHoraire ? "Modifier" : "Ajouter" }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template
                      v-slot:[`item.actions`]="{ item }"
                      v-if="!isAction"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-1"
                            v-on="on"
                            v-bind="attrs"
                            fab
                            x-small
                            color="green"
                            outlined
                            @click.prevent="editHoraires(item)"
                            key="edit"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Modifiez le créneau</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-1"
                            v-on="on"
                            v-bind="attrs"
                            fab
                            x-small
                            color="error"
                            outlined
                            @click.prevent="opendeleteModal(item)"
                            key="delete"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Supprimez le créneau</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs>

            <v-card-actions class="">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :loading="loading"
                :disabled="
                  !(
                    GroupeCreneau.EST_PaiementAutre ||
                    GroupeCreneau.EST_PaiementCB
                  )
                "
                rounded
                color="blue-dark text-white font-weight-bold"
                >Enregistrer</v-btn
              >
              <!-- :disabled="isAction" -->
            </v-card-actions>
          </ValidateForm>
          <v-overlay :value="lauchOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
        <ModalUsers
          v-if="userModalIsOpen"
          :context="{
            ID_Structure: currentStructureId,
            ID_Saison: this.currentSaison.id,
          }"
          :isOpen="userModalIsOpen"
          @onClose="userModalIsOpen = false"
          @onUserSelected="setUser"
        ></ModalUsers>
      </v-tabs-items>
      <YesNo
        :showDialog="confirmDeleteModal"
        title="CONFIRMATION"
        text="Souhaitez-vous vraiment supprimer ce créneau?"
        @answerYes="deleteHoraire"
        @answerNo="confirmDeleteModal = false"
        @close="confirmDeleteModal = false"
        key="Creneau"
      ></YesNo>
      <YesNo
        :showDialog="confirmDeleteParticipant"
        title="CONFIRMATION"
        text="Souhaitez-vous supprimer cette demande d'adhésion ?"
        @answerYes="DeleteDemandeParticipant"
        @answerNo="confirmDeleteParticipant = false"
        @close="confirmDeleteParticipant = false"
        key="participant"
      ></YesNo>
      <ConfirmationRefus
        :openDialog="confirmRefusParticipant"
        title="Veuillez spécifier la raison du refus"
        @answerYes="refuseParticipantDemande"
        @answerNo="confirmRefusParticipant = false"
        @close="confirmRefusParticipant = false"
        key="refus"
        text_yes="Valider"
        text_no="Annuler"
      ></ConfirmationRefus>
    </div>
  </v-dialog>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import InputSelect from "@/components/utils/select.vue";
import Textarea from "@/components/utils/textarea.vue";
import ModalUsers from "@/components/utils/modals/users.vue";
import ConfirmationRefus from "@/components/utils/modals/Licence/ConfirmationRefus.vue";
import { mapActions, mapGetters } from "vuex";
import YesNo from "@/components/utils/modals/yes-no.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { send_email } from "@/plugins/utils";

export default {
  components: {
    ValidateForm,
    InputText,
    InputSelect,
    Textarea,
    YesNo,
    ModalUsers,
    ConfirmationRefus,
  },

  data: () => ({
    confirmRefusParticipant: false,
    lauchOverlay: false,
    confirmDeleteModal: false,
    editHoraire: false,
    dialog: false,
    loading: false,
    mounted: false,
    sectionList: null,
    SelectedSaison: null,
    userModalIsOpen: false,
    activeTab: 0,
    Essai: null,
    participants: [],
    currentParticipant: "",
    GroupeCreneau: {
      NomGroupe: null,
      AgeMinimum: null,
      AgeMaximum: null,
      Description: null,
      NiveauPrerequis: null,
      EST_PaiementCB: false,
      EST_PaiementAutre: false,
      ModaliteOrganisation: null,
      ID_PratiqueType: null,
      ID_PratiqueNiveau: null,
    },

    OptionsAdhesions: [],
    ID_Section: null,
    NomEncadrant: null,
    ID_Tarif: null,
    EST_VenteOuverteExterne: false,
    EST_VenteOuverteClub: false,
    GroupeSaison: {
      ID_Encadrant: null,
      QuotasMax: null,
    },
    GroupeHoraires: {
      ID_AdressePratique: null,
      ID_CodeCouleur: null,
      Description: null,
      Z_HeureDebut: null,
      ID_NumeroJour: null,
    },
    EncadrantInfo: {},
    tarifsClub: null,
    menu1: null,
    menu2: null,
    allDays: [
      { text: "Lundi", value: 1 },
      { text: "Mardi", value: 2 },
      { text: "Mercredi", value: 3 },
      { text: "Jeudi", value: 4 },
      { text: "Vendredi", value: 5 },
      { text: "Samedi", value: 6 },
      { text: "Dimanche", value: 7 },
    ],

    listCreneau: [],
    editedCreneau: {
      ID_NumeroJour: "",
      Description: "",
      Z_HeureDebut: "",
      Z_HeureFin: "",
      ID_AdressePratique: "",
    },
    adressesStructure: null,
    itemToEditTemp: null,
    itemToDelete: null,
    AllTarifsClub: [],
    optionClub: [],
    confirmDeleteParticipant: false,
    participantToDelete: {},
    participantToRefuse: {},
    listPratiqueType: [],
    listPratiqueNiveau: [],
  }),

  async created() {
    this.mounted = true;
    await this.setData();
    this.SelectedSaison = this.context.ID_Saison;
  },
  computed: {
    ...mapGetters("structure", [
      "currentStructureId",
      "hasCodeFederal",
      "currentStructure",
    ]),
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("saisons", ["currentSaison"]),
    participantsHeaders() {
      if (this.SelectedSaison === this.currentSaison.id) {
        return [
          {
            text: "N° Licence",
            align: "center",
            value: "LicenceNumero",
          },
          { text: "Prénom", value: "CT_Prenom" },
          { text: "Nom", value: "CT_Nom" },
          { text: "Age", value: "Age" },

          {
            text: "Date inscription",
            value: "DateInscription",
            sort: (a, b) => {
              const S = a.split("/");
              const B = b.split("/");
              return new Date(S[2], S[1], S[0]) - new Date(B[2], B[1], B[0]);
            },
          },
          {
            text: "Confirmation",
            value: "Status",
          },
        ];
      } else
        return [
          {
            text: "N° Licence",
            align: "center",
            value: "LicenceNumero",
          },
          { text: "Prénom", value: "CT_Prenom" },
          { text: "Nom", value: "CT_Nom" },
          { text: "Age", value: "Age" },
          { text: "Date inscription", value: "DateInscription" },
        ];
    },

    modalityOrganizationList() {
      return [
        { text: "Encadré", value: 1 },
        { text: "Non-encadrée / Autonome", value: 2 },
      ];
    },

    error() {
      if (
        parseInt(this.GroupeCreneau.AgeMaximum) <
        parseInt(this.GroupeCreneau.AgeMinimum)
      )
        return true;
      else return false;
    },
    isDisabled() {
      if (
        this.editedCreneau.ID_NumeroJour &&
        this.editedCreneau.Z_HeureDebut &&
        this.editedCreneau.Z_HeureFin &&
        this.editedCreneau.ID_AdressePratique &&
        this.editedCreneau.Z_HeureDebut < this.editedCreneau.Z_HeureFin
      ) {
        return false;
      } else return true;
    },
    errorInput() {
      if (
        this.editedCreneau.Z_HeureDebut &&
        this.editedCreneau.Z_HeureFin &&
        this.editedCreneau.Z_HeureDebut > this.editedCreneau.Z_HeureFin
      ) {
        return true;
      } else return false;
    },
    headers() {
      if (this.isAction) {
        const headers = [
          {
            text: "Jour",
            sortable: false,
            value: "Jour",
          },
          { text: "Heure debut", value: "Z_HeureDebut" },
          { text: "Heure fin", value: "Z_HeureFin" },
          { text: "Lieu de pratique", value: "AdressePratique" },
          { text: "Déscription", value: "Description" },
        ];
        return headers;
      } else {
        const headers = [
          {
            text: "Jour",
            sortable: false,
            value: "Jour",
          },
          { text: "Heure debut", value: "Z_HeureDebut" },
          { text: "Heure fin", value: "Z_HeureFin" },
          { text: "Lieu de pratique", value: "AdressePratique" },
          { text: "Déscription", value: "Description" },
          { text: "Actions", value: "actions", sortable: false },
        ];
        return headers;
      }
    },
    saisonList() {
      if (this.defaultCreneau?.STR_GroupeSaisonCreneau?.length > 0) {
        let saisons = [];
        let saisonsSorted = [];
        for (let i = 0; i < 2; i++) {
          let data = this.defaultCreneau?.STR_GroupeSaisonCreneau?.map(
            (groupesaison) => ({
              title: `Saison ${groupesaison.ID_Saison - 1 + i}-${
                groupesaison.ID_Saison + i
              }`,
              value: groupesaison.ID_Saison + i,
            })
          );
          saisonsSorted = data.sort((a, b) => (a.value > b.value ? -1 : 1));
          saisons.push(...saisonsSorted);
        }
        return saisons;
      } else {
        let saisons = [];
        for (let i = 0; i < 2; i++) {
          let data = [
            {
              title: `Saison ${this.context.ID_Saison - 1 + i}-${
                this.context.ID_Saison + i
              }`,
              value: this.context.ID_Saison + i,
            },
          ];
          saisons.push(...data);
        }
        return saisons;
      }
    },
    isAction() {
      if (this.SelectedSaison !== this.currentSaison.id) {
        return true;
      } else return false;
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureActiviteFromStrID",
      "getActivitieDelegation",
      "getAffiliationTarifStructure",
      "createGroupeCreneau",
      "getStructureAdresses",
      "deleteCreneauHoraire",
      "deleteDemandeLicence",
      "updateDemandeLicence",
    ]),
    ...mapActions("basics", ["getListPratiqueType", "getListPratiqueNiveau"]),

    // custom
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "date") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },

    test(item) {
      this.currentParticipant = item;
    },
    // horaires traitement
    opendeleteModal(item) {
      this.itemToDelete = { ...item };
      this.confirmDeleteModal = true;
    },
    async refuseParticipantDemande(msg) {
      await this.updateDemandeLicence({
        UTI_UtilisateursTemp: this.participantToRefuse,
        status: 2,
        groupeCreneauID: this.defaultCreneau?.id,
      });
      let value = {
        ...this.participants.find(
          (participant) => participant.id === this.participantToRefuse.id
        ),
        Status: 2,
      };
      this.participants.splice(
        this.participants.findIndex(
          (participant) => participant.id === this.participantToRefuse.id
        ),
        1,
        value
      );
      this.confirmRefusParticipant = false;
      this.sendEmailDemandeRefus(msg);
    },

    async sendEmailDemandeRefus(msg) {
      let payload = {
        User: this.currentUserId, // l'utitlisateur cible
        Context: "sendemailRequestRefused",
        Email: this.currentParticipant?.CT_Email,
        Comment: msg,
        Nom: this.currentParticipant?.CT_Nom,
        Prenom: this.currentParticipant?.CT_Prenom,
        Structure: this.currentStructure?.STR_Structure?.NomStructure,
      };

      await send_email(payload);
    },
    //  Link: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-stop`,
    //  Link: linkPayment,
    async sendEmailDemandeAcceptedCB(linkPayment) {
      let payload = {
        User: this.currentUserId, // l'utitlisateur cible
        Context: "sendemailRequestAccept",
        Email: this.currentParticipant?.CT_Email,
        Link: `${process.env.VUE_APP_FRONT_URL}lemonway/payment-stop`,
        Nom: this.currentParticipant?.CT_Nom,
        Prenom: this.currentParticipant?.CT_Prenom,
        Structure: this.currentStructure?.STR_Structure?.NomStructure,
      };

      await send_email(payload);
    },

    async sendEmailDemandeAcceptedOther() {
      let payload = {
        User: this.currentUserId, // l'utitlisateur cible
        Context: "sendemailRequestAcceptOther",
        Email: this.currentParticipant?.CT_Email,
        Nom: this.currentParticipant?.CT_Nom,
        Prenom: this.currentParticipant?.CT_Prenom,
        Structure: this.currentStructure?.STR_Structure?.NomStructure,
      };

      await send_email(payload);
    },

    //PAYMENT LEMONWAY FOR ACCEPTED USER
    async setPayment(PaymentInfos) {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/bigData`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(PaymentInfos),
          }
        );

        const jsonBigData = await response;
        if (jsonBigData.ok === true) {
          await this.callPayment();
        }

        return { ok: true };
      } catch (error) {
        return { ok: false };
      }
    },

    async callPayment() {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/webinit`,
          {
            method: "get",
            headers: headers,
          }
        );
        const json = await response.json();

        if (json.webKitToken !== null) {
          const linkPayment = `${process.env.VUE_APP_LEMONWAY_WEBKIT}/?moneyintoken=${json.webKitToken}`;

          // window.open(
          //   `${process.env.VUE_APP_LEMONWAY_WEBKIT}/?moneyintoken=${json.webKitToken}`
          // );

          this.sendEmailDemandeAcceptedCB(linkPayment);
        }

        return { ok: true };
      } catch (error) {
        return { ok: false };
      }
    },

    async deleteHoraire() {
      this.loading = true;
      if (this.itemToDelete.id) {
        await this.deleteCreneauHoraire({
          groupe: { ...this.defaultCreneau },
          ID_Horaire: this.itemToDelete.id,
          currentSaison: this.currentSaison.id,
        });
        this.deleteHoraireLocaly();
      } else this.deleteHoraireLocaly();

      this.confirmDeleteModal = false;
    },
    deleteHoraireLocaly() {
      this.listCreneau = this.listCreneau.filter(
        (creneau) =>
          creneau.ID_NumeroJour !== this.itemToDelete.ID_NumeroJour ||
          creneau.Description !== this.itemToDelete.Description
      );
      this.loading = false;
    },
    openHoraireModal() {
      this.editHoraire = false;
      this.editedCreneau = {};
      this.dialog = true;
    },
    editHoraires(itemToEdit) {
      this.editHoraire = true;
      this.editedCreneau = { ...itemToEdit };
      this.itemToEditTemp = { ...itemToEdit };
      this.dialog = true;
    },
    close() {
      this.$emit("onClose");
    },

    save() {
      const {
        ID_NumeroJour,
        Description,
        Z_HeureDebut,
        Z_HeureFin,
        ID_AdressePratique,
      } = this.editedCreneau;
      const AdressePratique = this.adressesStructure.find(
        (adresse) => adresse.id === ID_AdressePratique
      );
      if (this.editHoraire) {
        const CreneauFiltered = this.listCreneau.filter(
          (creneau) =>
            creneau.ID_NumeroJour !== this.itemToEditTemp.ID_NumeroJour ||
            creneau.Description !== this.itemToEditTemp.Description
        );
        this.listCreneau = [
          ...CreneauFiltered,
          {
            ID_NumeroJour,
            Description,
            Z_HeureDebut,
            Z_HeureFin,
            ID_AdressePratique,
            AdressePratique: `${AdressePratique.Adresse1} (${AdressePratique.Ville})`,
            id: this.itemToEditTemp.id,
            Jour:
              ID_NumeroJour === 1
                ? "Lundi"
                : ID_NumeroJour === 2
                ? "Mardi"
                : ID_NumeroJour === 3
                ? "Mercredi"
                : ID_NumeroJour === 4
                ? "Jeudi"
                : ID_NumeroJour === 5
                ? "Vendredi"
                : ID_NumeroJour === 6
                ? "Samedi"
                : "Dimanche",
          },
        ];
      } else {
        this.listCreneau = [
          ...this.listCreneau,
          {
            ID_NumeroJour,
            Description,
            Z_HeureDebut,
            Z_HeureFin,
            ID_AdressePratique,
            AdressePratique: `${AdressePratique.Adresse1} (${AdressePratique.Ville})`,

            Jour:
              ID_NumeroJour === 1
                ? "Lundi"
                : ID_NumeroJour === 2
                ? "Mardi"
                : ID_NumeroJour === 3
                ? "Mercredi"
                : ID_NumeroJour === 4
                ? "Jeudi"
                : ID_NumeroJour === 5
                ? "Vendredi"
                : ID_NumeroJour === 6
                ? "Samedi"
                : "Dimanche",
          },
        ];
      }

      this.dialog = false;
    },
    openSelectUserModal() {
      // if (this.hasCodeFederal && !this.isAction) {
      //   this.userModalIsOpen = true;
      // }
      if (this.hasCodeFederal) {
        this.userModalIsOpen = true;
      }
    },

    setUser(user) {
      if (user) {
        this.EncadrantInfo = { ...user };
        this.selectedUser = user;
        this.GroupeSaison.ID_Encadrant = user.UTI_Utilisateur.id;
        this.NomEncadrant =
          user.UTI_Utilisateur.CT_Prenom + " " + user.UTI_Utilisateur.CT_Nom;
      }
      this.userModalIsOpen = false;
    },

    async setData() {
      await this.getListActiviteStructure();
      await this.getListTarifClub();
      this.listPratiqueType = await this.getListPratiqueType();
      this.listPratiqueNiveau = await this.getListPratiqueNiveau();

      const adresses = await this.getStructureAdresses({
        ID_Structure: this.currentStructureId,
      });
      this.adressesStructure = adresses.map((adresse) => ({
        ...adresse,
        adresseVille: `${adresse.Adresse1.toLowerCase()} (${adresse?.Ville.toUpperCase()})`,
      }));
    },
    async getListActiviteStructure() {
      let response = await this.getActivitieDelegation();
      return (this.sectionList = [...response]);
    },
    async getListTarifClub() {
      let response = await this.getAffiliationTarifStructure({
        ID_Structure: this.currentStructureId,
      });
      response = response.map((list) => ({
        ...list,
        NomEtPrix: `${list.NomTarif} (${list.MontantUnitaire} €)`,
      }));

      this.AllTarifsClub = [...response];
      this.tarifsClub = this.AllTarifsClub.filter(
        (tarif) => !tarif.EST_Objet && tarif.ID_Saison === this.saisonGroupe
      );

      this.optionClub = this.AllTarifsClub.filter(
        (tarif) => tarif.EST_Objet && tarif.ID_Saison === this.saisonGroupe
      );
    },
    async acceptParticipant(item) {
      if (
        !this.GroupeCreneau.EST_PaiementCB &&
        !this.GroupeCreneau.EST_PaiementAutre
      ) {
        error_notification("Veuillez sélectionner un mode de paiement accepté");
      } else {
        await this.updateDemandeLicence({
          UTI_UtilisateursTemp: item,
          status: 1,
          groupeCreneauID: this.defaultCreneau.id,
        });
        let value = {
          ...this.participants.find(
            (participant) => participant.id === item.id
          ),
          Status: 1,
        };
        this.participants.splice(
          this.participants.findIndex(
            (participant) => participant.id === item.id
          ),
          1,
          value
        );
        this.confirmDeleteModal = false;

        // envoi mail d'acceptation
        if (this.GroupeCreneau.EST_PaiementCB === true) {
          let PaymentInfos = JSON.parse(item.PaymentInformation);
          PaymentInfos.comment = `LicenceDematerialise:${item.id}`;
          // PaymentInfos.returnUrl = 'http://localhost:8080/lemonway/payment-success';

          // this.setPayment(PaymentInfos); //a remettre prochainement
          // this.$router.push({ name: 'page_de_paiement_ffme' });
          this.sendEmailDemandeAcceptedCB();
        } else if (this.GroupeCreneau.EST_PaiementAutre === true) {
          this.sendEmailDemandeAcceptedOther();
        }
      }
    },
    async submit() {
      // groupe creneeau data
      const optionsAdh = this.OptionsAdhesions.map((option) => ({
        ID: option,
      }));
      const groupeCreneau = this.isEdit
        ? {
            ...this.GroupeCreneau,
            id: this.defaultCreneau.id,
            AgeMinimum: parseInt(this.GroupeCreneau.AgeMinimum),
            AgeMaximum: parseInt(this.GroupeCreneau.AgeMaximum),
            ID_Structure: this.currentStructureId,
            ID_Saison: this.saisonGroupe,
            ID_Section: this.ID_Section,
            OptionAdhesion: JSON.stringify(optionsAdh),
          }
        : {
            ...this.GroupeCreneau,
            AgeMinimum: parseInt(this.GroupeCreneau.AgeMinimum),
            AgeMaximum: parseInt(this.GroupeCreneau.AgeMaximum),
            ID_Structure: this.currentStructureId,
            // ID_Saison: this.currentSaison.id,
            ID_Saison: this.saisonGroupe,
            ID_Section: this.ID_Section,
            OptionAdhesion: JSON.stringify(this.OptionsAdhesions),
          };
      // groupe saison data
      const groupeSaison = this.isEdit
        ? {
            ...this.GroupeSaison,
            id: this.defaultCreneau.STR_GroupeSaisonCreneau[0].id,
            QuotasMax: parseInt(this.GroupeSaison.QuotasMax),
            // ID_Saison: this.currentSaison.id,
            ID_Saison: this.saisonGroupe,
            ID_Encadrant:
              this.GroupeSaison.ID_Encadrant === null
                ? this.defaultCreneau.STR_GroupeSaisonCreneau[0].UTI_Utilisateur
                    .id
                : this.GroupeSaison.ID_Encadrant,
            ID_Tarif: this.ID_Tarif,
            EST_VenteOuverteExterne: this.EST_VenteOuverteExterne,
            EST_VenteOuverteClub: this.EST_VenteOuverteClub,
          }
        : {
            ...this.GroupeSaison,
            QuotasMax: parseInt(this.GroupeSaison.QuotasMax),
            // ID_Saison: this.currentSaison.id,
            ID_Saison: this.saisonGroupe,
            ID_Tarif: this.ID_Tarif,
            EST_VenteOuverteExterne: this.EST_VenteOuverteExterne,
            EST_VenteOuverteClub: this.EST_VenteOuverteClub,
          };
      const groupeHoraire = this.listCreneau.map((creneau) => ({
        Description: creneau.Description,
        ID_AdressePratique: creneau.ID_AdressePratique,
        ID_CodeCouleur: creneau.ID_CodeCouleur,
        ID_NumeroJour: creneau.ID_NumeroJour,
        Z_HeureDebut: creneau.Z_HeureDebut,
        Z_HeureFin: creneau.Z_HeureFin,
        id: creneau.id,
      }));

      const dataGlobal = {
        ...groupeCreneau,
        STR_GroupeSaisonCreneau: {
          data: {
            ...groupeSaison,
            STR_GroupeCreneauHoraires: {
              data: groupeHoraire,
              on_conflict: {
                constraint: "STR_GroupeCreneauHoraires_pkey",
                update_columns: [
                  "ID_AdressePratique",
                  "ID_CodeCouleur",
                  "Description",
                  "Z_HeureDebut",
                  "Z_HeureFin",
                  "ID_NumeroJour",
                ],
              },
            },
          },
          on_conflict: {
            constraint: "STR_GroupeSaisonCreneau_pkey",
            update_columns: [
              "ID_Encadrant",
              "ID_Selection",
              "ID_Tarif",
              "EST_VenteOuverteExterne",
              "EST_VenteOuverteClub",
              "QuotasMax",
            ],
          },
        },
      };
      const dataToSend = [dataGlobal];
      await this.createGroupeCreneau(dataToSend);
      this.$emit("reloadGroupe");
      this.close();
    },
    deleteEncadrant() {
      this.NomEncadrant = null;
      this.defaultCreneau.STR_GroupeSaisonCreneau[0].UTI_Utilisateur.id = null;
      success_notification("Encadrant référent retiré");
    },

    predefineData() {
      // groupe creneau
      if (this.defaultCreneau) {
        this.GroupeCreneau.NomGroupe = this.defaultCreneau.NomGroupe;
        this.GroupeCreneau.Description = this.defaultCreneau.Description;
        this.GroupeCreneau.AgeMinimum = this.defaultCreneau.AgeMinimum;
        this.GroupeCreneau.AgeMaximum = this.defaultCreneau.AgeMaximum;
        this.GroupeCreneau.NiveauPrerequis = this.defaultCreneau.NiveauPrerequis;
        this.GroupeCreneau.EST_PaiementCB = this.defaultCreneau.EST_PaiementCB;
        this.GroupeCreneau.EST_PaiementAutre = this.defaultCreneau.EST_PaiementAutre;
        this.GroupeCreneau.ModaliteOrganisation = this.defaultCreneau.ModaliteOrganisation;
        this.GroupeCreneau.ID_PratiqueType = this.defaultCreneau.ID_PratiqueType;
        this.GroupeCreneau.ID_PratiqueNiveau = this.defaultCreneau.ID_PratiqueNiveau;

        // this.GroupeCreneau.ID_Section = this.defaultCreneau.LIST_ActiviteType.id;
        const options = JSON.parse(this.defaultCreneau.OptionAdhesion);
        const optionAdhesion = options
          ? options.map((option) => (option && option.ID) || option)
          : [];
        this.OptionsAdhesions = [...optionAdhesion];
        //creneau par saison
        const groupeSaison = this.defaultCreneau?.STR_GroupeSaisonCreneau?.find(
          (groupe) => groupe.ID_Saison === this.saisonGroupe
        );

        //formatage des participants
        const participants =
          groupeSaison && groupeSaison.UTI_UtilisateursTemps
            ? [...groupeSaison.UTI_UtilisateursTemps]
            : [];
        this.participants = participants.length
          ? participants.map((participant) => {
              //traitement age
              const now = this.$moment(new Date());
              const birth = this.$moment(participant.DN_DateNaissance);
              const duration = this.$moment.duration(now.diff(birth));
              const years = duration.asYears();

              return {
                ...participant,

                DateInscription: this.$moment(
                  participant.Z_DateCreationDemande
                ).format("DD/MM/YYYY"),

                Age: `${parseInt(years)} ans`,
              };
            })
          : [];

        this.NomEncadrant = `${
          groupeSaison.UTI_Utilisateur
            ? groupeSaison.UTI_Utilisateur.CT_Nom
            : ""
        } ${
          groupeSaison.UTI_Utilisateur
            ? groupeSaison.UTI_Utilisateur.CT_Prenom
            : ""
        }`;
        this.GroupeSaison.QuotasMax = groupeSaison && groupeSaison.QuotasMax;
        this.ID_Section = this.defaultCreneau.ID_Section;
        this.ID_Tarif = groupeSaison && groupeSaison.ID_Tarif;
        this.EST_VenteOuverteExterne =
          groupeSaison && groupeSaison.EST_VenteOuverteExterne;
        this.EST_VenteOuverteClub =
          groupeSaison && groupeSaison.EST_VenteOuverteClub;
        const horaires = [...groupeSaison.STR_GroupeCreneauHoraires];
        this.listCreneau = horaires.length
          ? horaires.map((horaire) => ({
              ...horaire,
              AdressePratique: `${horaire.ADR_Adresse.Adresse1} (${horaire?.ADR_Adresse.Ville})`,
              Jour:
                horaire.ID_NumeroJour === 1
                  ? "Lundi"
                  : horaire.ID_NumeroJour === 2
                  ? "Mardi"
                  : horaire.ID_NumeroJour === 3
                  ? "Mercredi"
                  : horaire.ID_NumeroJour === 4
                  ? "Jeudi"
                  : horaire.ID_NumeroJour === 5
                  ? "Vendredi"
                  : horaire.ID_NumeroJour === 6
                  ? "Samedi"
                  : "Dimanche",
            }))
          : [];
      }
    },
    restoreData() {
      // groupe creneau
      this.GroupeCreneau.NomGroupe = null;
      this.GroupeCreneau.Description = null;
      this.GroupeCreneau.AgeMinimum = null;
      this.GroupeCreneau.AgeMaximum = null;
      this.GroupeCreneau.NiveauPrerequis = null;
      this.GroupeCreneau.ID_Section = null;
      this.GroupeCreneau.EST_PaiementCB = false;
      this.GroupeCreneau.EST_PaiementAutre = false;
      this.GroupeCreneau.ModaliteOrganisation = null;
      this.GroupeCreneau.ID_PratiqueType = null;
      this.GroupeCreneau.ID_PratiqueNiveau = null;

      //creneau par saison
      this.NomEncadrant = null;
      this.GroupeSaison.QuotasMax = null;
      this.ID_Section = null;
      this.ID_Tarif = null;
      this.EST_VenteOuverteExterne = false;
      this.EST_VenteOuverteClub = false;
      this.listCreneau = [];
      this.OptionsAdhesions = [];
    },
    synchroniseDataBySaisons(saisonSelected) {
      const groupeSaison = this.defaultCreneau?.STR_GroupeSaisonCreneau?.find(
        (groupe) => groupe.ID_Saison === saisonSelected
      );
      if (groupeSaison) {
        this.NomEncadrant = `${groupeSaison.UTI_Utilisateur.CT_Nom} ${groupeSaison.UTI_Utilisateur.CT_Prenom}`;
        this.GroupeSaison.QuotasMax = groupeSaison.QuotasMax;
        this.ID_Tarif = groupeSaison.ID_Tarif;
        this.EST_VenteOuverteExterne = groupeSaison.EST_VenteOuverteExterne;
        this.EST_VenteOuverteClub = groupeSaison.EST_VenteOuverteClub;
        const horaires = [...groupeSaison.STR_GroupeCreneauHoraires];
        this.listCreneau = horaires.map((horaire) => ({
          ...horaire,
          AdressePratique: `${horaire.ADR_Adresse?.Adresse1} (${horaire.ADR_Adresse?.Ville})`,
          Jour:
            horaire.ID_NumeroJour === 1
              ? "Lundi"
              : horaire.ID_NumeroJour === 2
              ? "Mardi"
              : horaire.ID_NumeroJour === 3
              ? "Mercredi"
              : horaire.ID_NumeroJour === 4
              ? "Jeudi"
              : horaire.ID_NumeroJour === 5
              ? "Vendredi"
              : horaire.ID_NumeroJour === 6
              ? "Samedi"
              : "Dimanche",
        }));
        //formatage des participants
        const participants = groupeSaison.UTI_UtilisateursTemps
          ? [...groupeSaison.UTI_UtilisateursTemps]
          : [];
        this.participants = participants.length
          ? participants.map((participant) => {
              const now = this.$moment(new Date());
              const birth = this.$moment(participant.DN_DateNaissance);
              const duration = this.$moment.duration(now.diff(birth));
              const years = duration.asYears();
              return {
                ...participant,

                DateInscription: this.$moment(
                  participant.Z_DateCreationDemande
                ).format("DD/MM/YYYY"),
                Age: `${parseInt(years)} ans`,
              };
            })
          : [];
      }
    },

    setListeCreneaux() {
      this.listeCreneaux.push({
        day:
          this.creneauData.Z_jour === 1
            ? "lundi"
            : this.creneauData.Z_jour === 2
            ? "mardi"
            : this.creneauData.Z_jour === 3
            ? "mercredi"
            : this.creneauData.Z_jour === 4
            ? "jeudi"
            : this.creneauData.Z_jour === 5
            ? "vendredi"
            : this.creneauData.Z_jour === 6
            ? "samedi"
            : "dimanche",
        start: this.creneauData.Z_heure_debut,
        end: this.creneauData.Z_heure_fin,
      });
    },

    removeChipsDays(item) {
      this.listeCreneaux.splice(this.listeCreneaux.indexOf(item), 1);
      this.listeCreneaux = [...this.listeCreneaux];
    },
    // suppression participant
    deleteParticipant(item) {
      this.confirmDeleteParticipant = true;
      this.participantToDelete = { ...item };
    },
    async DeleteDemandeParticipant() {
      await this.deleteDemandeLicence({
        UTI_UtilisateursTemp: this.participantToDelete,
      });
      this.confirmDeleteParticipant = false;
      const newParticipants = this.participants.filter(
        (participant) => participant.id !== this.participantToDelete.id
      );
      this.participants = [...newParticipants];
      this.$emit("reloadGroupe");
    },
    refuseParticipant(item) {
      this.participantToRefuse = { ...item };
      this.confirmRefusParticipant = true;
    },
  },
  watch: {
    defaultCreneau() {
      if (this.isEdit) {
        this.predefineData();
        this.getListTarifClub();
      } else {
        this.getListTarifClub();
        this.restoreData();
      }
    },
    SelectedSaison() {
      this.lauchOverlay = true;
      setTimeout(() => {
        this.lauchOverlay = false;
      }, 1000);
      if (this.defaultCreneau) {
        this.synchroniseDataBySaisons(this.saisonGroupe);
      }
    },
  },

  props: {
    saisonGroupe: {
      type: Number,
    },
    modalIsOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
    },
    defaultCreneau: {
      type: Object || null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.selection_vente .v-input__slot .v-label {
  margin-bottom: 0rem !important;
}
</style>
<style lang="scss" scoped>
.field_creneau {
  margin-bottom: 0;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.v-toolbar__content {
  display: flex;
  justify-content: center !important;
}
.field_creneau {
  margin-top: 25px !important;
}
.v-window {
  min-height: 288px !important;
}
.v-card {
  min-height: 36vh;
}
.list__participant .v-data-table__wrapper {
  min-height: 260px !important;
}
.list__participant .v-window-item--active {
  height: 320px !important ;
}
.creneau__list .v-toolbar--flat {
  height: 30px !important;
}
.creneau__list .v-data-table__wrapper {
  min-height: 220px !important;
}
.tabs__list .v-item-group {
  margin-top: 10px !important;
}
// .selection_vente >>> label {
//   margin-bottom: 0rem !important;
//   background-color: red;
// }
.encadrant__choice .field__value-column {
  padding-left: 10px;
}
.encadrant__choice .field__value-column input {
  max-width: 95% !important;
}
.minimun__age .field__value-column.col-md-9 input {
  //width: 25%;
  background: red;
}

// .v-tab {
//   width: 33% !important;
// }

.v-dialog .v-icon {
  top: inherit !important;
}
</style>
