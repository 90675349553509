<template>
  <v-dialog
    v-model="openDialog"
    :max-width="maxWidth"
    class="ffme__confirm-dialog"
    @click:outside="$emit('answerNo')"
    origin
  >
    <v-card>
      <v-toolbar
        color="blue-dark"
        style="display: flex; justify-content: center;"
        dark
        >{{ title }}</v-toolbar
      >

      <v-textarea
        solo
        v-model="msg"
        name="input-7-4"
        label="Spécifiez la raison du refus"
        clear-icon="mdi-close-circle"
        prepend-inner-icon="mdi-comment"
        style="width: 420px;"
        :autofocus="focus"
      ></v-textarea>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="red"
          text
          class="__full mt-0 mr-3 text-uppercase text-white"
          @click="$emit('answerNo')"
        >
          {{ text_no }}
        </v-btn>

        <v-btn
          color="blue-dark text-white font-weight-bold"
          rounded
          small
          class="__full __icon-left"
          @click="$emit('answerYes', msg)"
        >
          {{ text_yes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Êtes-vous sûr?",
    },
    text_yes: {
      type: String,
      default: "Oui",
    },
    text_no: {
      type: String,
      default: "Non",
    },
    maxWidth: {
      type: [Number, String],
      default: 420,
    },
  },
  data() {
    return {
      msg: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.confirm-dialog__headline {
  word-break: break-word;
}
.v-toolbar__content {
  justify-content: center !important;
}
.v-input {
  padding: 8px !important;
  margin-top: 19px !important;
}
</style>
